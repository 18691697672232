<template>
    <!-- 基本资料 -->
    <div class="basic-data">
        <el-form
            size="mini"
            label-width="300px"
            label-position="left"
            class="basic-data-form">
            <!-- 头像 -->
            <el-form-item label="头像 / Avatar">
                <div style="color: #E6A23C">建议图片大小2M以内</div>
                <el-upload
                    class="avatar-uploader"
                    :action="$_url_.baseUrl+'usercenter/userhead'"
                    :http-request="handleRequest"
                    :show-file-list="false">
                    <div class="avatar_box">
                        <img v-if="basic_data.head" :src="basic_data.head">
                        <img v-else :src="require('@/assets/images/avatar.png')">
                    </div>
                </el-upload>
                <span class="change_btn avatar_tips">点击头像进行修改</span>
            </el-form-item>
            <!-- 昵称 -->
            <el-form-item label="昵称 / Nickname">
                <el-input
					type="text"
					maxlength="11"
                    v-model="form.nickname"
                    class="center_basic_data_setbox"
                    clearable>
                </el-input>
            </el-form-item>
            <!-- 所在城市 -->
            <el-form-item label="所在城市 / City">
                <area-cascader 
                    v-model="form.city"
                    type='text'
                    :level='1'
                    :data="$pcaa"
                    placeholder="请选择地址"
                    class="center_basic_data_set_city">
                </area-cascader>
            </el-form-item>
            <!-- 详细地址 -->
            <el-form-item label="详细地址 / Address">
                <el-input
					maxlength="30"
                    v-model="form.address"
                    class="center_basic_data_setbox"
                    clearable>
                </el-input>
            </el-form-item>
            <!-- 生日 -->
            <el-form-item label="生日 / Birthday">
                <el-date-picker
                    v-model="form.born"
                    value-format="yyyy-MM-dd"
                    class="center_basic_data_setbox"
                    type="date">
                </el-date-picker>
            </el-form-item>
            <!-- 性别 -->
            <el-form-item label="性别 / Gender">
                <el-radio v-model="form.sex" :label="0">女</el-radio>
                <el-radio v-model="form.sex" :label="1">男</el-radio>
            </el-form-item>
            <!-- 手机号码 -->
            <!-- <el-form-item label="手机号码 / Phone">
                <el-input
                    v-model="form.phone"
                    class="center_basic_data_setbox"
                    clearable>
                </el-input>
            </el-form-item> -->
            <!-- 电子邮箱 -->
            <el-form-item label="电子邮箱 / Email">
                <el-input
					maxlength="24"
                    v-model="form.email"
                    class="center_basic_data_setbox"
                    clearable>
                </el-input>
            </el-form-item>
            <!-- 微信 -->
            <el-form-item label="微信 / WeChat">
                <el-input
					maxlength="20"
                    v-model="form.wechat"
                    class="center_basic_data_setbox"
                    clearable>
                </el-input>
            </el-form-item>
            <!-- QQ -->
            <el-form-item label="QQ">
                <el-input
					maxlength="20"
                    v-model="form.qq"
                    class="center_basic_data_setbox"
                    clearable>
                </el-input>
            </el-form-item>
        </el-form>
        <!-- 收款信息 -->
        <div class="basic-receipt-title">收款信息</div>
        <ul class="basic-receipt-list">
            <li>
                <div class="basic-receipt-list-title">微信</div>
                <div class="basic-receipt-list-input">
                    <label>账号</label>
                    <el-input
						maxlength="20"
                        v-model="form.a_wechat"
                        size="mini"
                        clearable
                        class="center_basic_data_setbox">
                    </el-input>
                </div>
            </li>
            <li>
                <div class="basic-receipt-list-title">支付宝</div>
                <div class="basic-receipt-list-input">
                    <label>账号</label>
                    <el-input
						maxlength="20"
                        v-model="form.a_alipay"
                        size="mini"
                        clearable
                        class="center_basic_data_setbox">
                    </el-input>
                </div>
            </li>
            <li>
                <div class="basic-receipt-list-title">银行卡</div>
                <div class="basic-receipt-list-input">
                    <label>户名</label>
                    <el-input
						maxlength="20"
                        v-model="form.a_name"
                        size="mini"
                        clearable
                        class="center_basic_data_setbox">
                    </el-input>
                </div>
                <div class="basic-receipt-list-input">
                    <label>卡号</label>
                    <el-input
						maxlength="20"
                        v-model="form.a_card"
                        size="mini"
                        clearable
                        class="center_basic_data_setbox">
                    </el-input>
                </div>
                <div class="basic-receipt-list-input">
                    <label>开户行</label>
                    <el-input
						maxlength="20"
                        v-model="form.a_bank"
                        size="mini"
                        clearable
                        class="center_basic_data_setbox">
                    </el-input>
                </div>
            </li>
        </ul>
        <!-- 提交区域 -->
        <div style="margin-top:70px">
            <el-button 
                type="warning"
                @click="handleSubmit"
                class="give_up_btn">
                <span>保存</span>
            </el-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
    data(){
        return {
            form: {
                nickname: "",
                city: [],
                address: "",
                born: "",
                sex: "",
                phone: "",
                email: "",
                wechat: "",
                qq: "",
                a_wechat: "",
                a_alipay: "",
                a_name: "",
                a_card: "",
                a_bank: "",
            }
        }
    },
    computed: mapState({
        basic_data: state => state.user.basic_data,
        user_id: state => state.user.user_id,
    }),
    methods: {
        ...mapMutations([
            "setAvatar",
            "setBasicData"
        ]),
        ...mapActions([
            "getBasicData",
            "handleChangeBasic",
            "handleChangeAvatar"
        ]),
        handleRequest(val){
            const isLt2M = val.file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
                return ;
            }
            let reader = new FileReader()
            reader.onload = () => {
                let _base64 = reader.result
                let BASE64 = _base64.split(",")
                this.handleChangeAvatar(BASE64[1]).then((res) => {
                    if(res.type == "success"){
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: "success"
                        })
                    }
                }).catch(() => {})
            }
            reader.readAsDataURL(val.file)
        },
        filterTime(value){
            let time = new Date(value),
            y = time.getFullYear();
            // m = time.getMonth
        },
        setForm(basic){
            this.form = {
                nickname: basic.nickname,
                city: basic.city,
                address: basic.address,
                born: basic.born,
                sex: basic.sex,
                phone: basic.phone,
                email: basic.email,
                wechat: basic.wechat || "",
                qq: basic.qq || "",
                a_wechat: basic.a_wechat || "",
                a_alipay: basic.a_alipay || "",
                a_name: basic.a_name || "",
                a_card: basic.a_card || "",
                a_bank: basic.a_bank || ""
            }
        },
        handleSubmit(){
            let form = this.form;
            this.handleChangeBasic({
                nickname: form.nickname,
                city: form.city,
                address: form.address,
                born: form.born,
                sex: form.sex,
                phone: form.phone,
                email: form.email,
                wechat: form.wechat,
                qq: form.qq,
                a_wechat: form.a_wechat,
                a_alipay: form.a_alipay,
                a_name: form.a_name,
                a_card: form.a_card,
                a_bank: form.a_bank
            }).then((res) => {
                this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定'
                })
                // this.$message({
                //     showClose: true,
                //     message: res.msg,
                //     type: res.type
                // })
            })
        }
    },
    watch: {
        basic_data: {
            handler: "setForm",
            immediate: true
        }
    },
}
</script>

<style scoped>
/* element-ui */
.basic-data-form>>>.el-form-item__label{
    color: #909399;
}

.basic-data{
    padding: 20px 100px;
}
/* 头像 */
.avatar-uploader{
    display: inline-block;
    vertical-align: text-bottom;
    height: 52px;
}
.avatar-uploader>>>.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader>>>.el-upload:hover {
    border-color: #409EFF;
}
.avatar_box{
    position: relative;
    width: 50px;
    height: 50px;
}
.avatar_box::before{
    display: block;
    content: "";
    padding-top: 100%;
}
.avatar_box>img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}
/* 头像提示 */
.avatar_tips{
    float: right;
    line-height: 50px;
    color: #C0C4CC;
}
/* 输入框 */
.center_basic_data_setbox{
    width: 200px
}
/* 所在城市组件 */
.center_basic_data_set_city>>>.area-select{
    width: 200px;
    height: 28px;
}
.center_basic_data_set_city>>>.area-selected-trigger{
    padding: 0 20px 0 12px;
}
/* 收款信息标题 */
.basic-receipt-title{
    padding-bottom: 10px;
    border-bottom: 1px solid #E4E7ED;
    font-size: 25px;
    font-weight: bold;
    color: #303133;
}
/* 收款信息列表 */
.basic-receipt-list{
    padding: 20px 0;
    list-style: disc;
}
.basic-receipt-list>li{
    margin-bottom: 18px;
}
.basic-receipt-list-title{
    font-size: 16px;
    font-weight: bold;
    color: #606266;
}
.basic-receipt-list-input + .basic-receipt-list-input{
    margin-top: 10px;
}
.basic-receipt-list-input > label{
    width: 300px;
    color: #909399;
}
/* 上传按钮 */
.give_up_btn{
    width: 100px;
}
</style>
